<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { fireBaseSignIn } from "./db/firebaseaccount"
import { toast } from "./ux/toast"
import { useRouter } from "vue-router"
import { localGet } from "./db/preferences"
import { IonApp, IonRouterOutlet } from '@ionic/vue';
export default{
  components : {
    IonRouterOutlet, IonApp
  },
  data(){
    return{
      router : useRouter()
    }
  },
  async mounted(){
    const datos = await localGet("userdata")
    if(datos === null){
      console.log("Nos quedamos")
    }else{
      const datosDeff = JSON.parse(datos)
      await this.router.push("/tabs/tab1")
      await toast(`Bienvenido de nuevo, ${datosDeff.name}`)
    }
  }
}
</script>
