<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/tab1">
        <i class="fa-solid fa-calendar-day text-lg"></i>
          <ion-label>Pedir cita</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab2">
        <i class="fa-solid fa-calendar-check text-lg"></i>
          <ion-label>Mis citas</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/tab3">
        <i class="fa-solid fa-user text-lg"></i>
          <ion-label>Mi cuenta</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router"
import { localGet } from "../db/preferences"
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
export default{
  components : {
    IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet
  },
  data(){
    return{
      router : useRouter()
    }
  },
  async mounted(){
    console.log("Pasa...")
    const datos = await localGet("userdata")
    if(datos === null){
      window.location.href = "/"
    }else{
      await this.router.push("/tabs/tab1")
    }
  }
}
</script>
